import { FC } from 'react'
import Image from '@/components/Image'
import { SideBanner } from '@/api/types'
import { useRouter } from 'next/router' // Import Next.js router

export type Props = SideBanner

const Component: FC<Props> = ({ link, eyeCatching }) => {
  const router = useRouter()
  const isArticlePage = router.pathname.startsWith('/articles') // Adjust this based on your routing

  const url = isArticlePage ? "/new_iot_banner.jpg" : "/iot_banner.png"
  const targetLink = isArticlePage ? "https://mvno.dxhub.co.jp/" : link

  return (
    <a className="w-full aspect-video block" rel="noreferrer" href={targetLink} target="_blank">
      <Image size="w=100%" url={url} />
    </a>
  )
}

export default Component